import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 intro-text">
                <h1>
                  TAILOR-MADE <span>SOFTWARE</span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div className="row">
                  <a
                    href="#contact"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Cadastro rápido
                  </a>{" "}
                  <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Saiba mais
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-divider"></div>
    </header>
  );
};
