import React from "react";

export const Companies = (props) => {
  return (
    <div id="companies">
      <div className="container">
        <div className="section-title">
          <h1 className="text-center">
            EMPRESAS QUE CONFIAM E INDICAM A IC OUTSOURCING
          </h1>
        </div>
        <div className="col-md-12">
          <div className="companies-grid">
            {props.data
              ? props.data.map((d, i) => (
                  <img
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-4"
                    src={d.img}
                    alt=""
                    color="white"
                  />
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};
