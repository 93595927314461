import React from "react";

export const Stacks = (props) => {
  return (
    <div id="stacks" className="text-center">
      <div className="container">
        <h1>
          Somos especialistas nas tecnologias e aplicações mais utilizadas no
          mercado:
        </h1>
        <div className="stacks-grid col-md-12">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className="stacks-grid-item"
                >
                  <img src={d.img} alt={d.name} />
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
