import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
  phone: "",
  empresa: "",
  cargo: ""
};

const YOUR_SERVICE_ID = "service_cikrimq";
const YOUR_TEMPLATE_ID = "template_csymlce";
const PUB_KEY = "IikzvLSUoF8OgDNS3";

export const Contact = (props) => {
  const [values, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...values, [name]: value });
  };

  const clearState = (e) => {
    e.target.reset();
    setState({ ...initialState });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    console.log(e.target);
    emailjs
      .send(YOUR_SERVICE_ID,
        YOUR_TEMPLATE_ID,
        {
          from_name: values.name,
          to_name: "Ian Campelo",
          message: `${values.message}
          \nEmail: ${values.email}
          \nTelefone: ${values.phone}
          \nEmpresa: ${values.empresa}
          \nCargo: ${values.cargo}`,
        },
        PUB_KEY)
      .then(
        (result) => {
          console.log(result.text);
          clearState(e);
          setState({ ...initialState });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div className="contact" id="contact">
        <div className="container">
          <div className="col-md-6 contact-info">
            <h1 className="gradient-text section-title">Desenvolvimento de software sob medida para o seu negócio</h1>
            <p>
              Seu produto digital desenvolvido por um time de especialistas.
            </p>
            <p>
              Há mais de 11 anos criamos soluções para grandes empresas referências do mercado nacional e internacional.
            </p>
            <p>
              Independente do tamanho do seu projeto, temos a solução ideal para você.
            </p>
            <p>
              Somos resolvedores de problemas.
            </p>
            <p>
              <strong>Entre em contato e solicite uma proposta.</strong>
            </p>
          </div>
          <div className="col-md-6 contact-form">
            <div className="row">
              <div className="section-title">
                <h1 className="gradient-text">Cadastro rápido</h1>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="">Nome</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <label htmlFor="">E-mail</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <label htmlFor="">Telefone</label>
                  <input
                    type="phone"
                    id="phone"
                    name="phone"
                    className="form-control"
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <label htmlFor="">Empresa</label>
                  <input
                    type="text"
                    id="empresa"
                    name="empresa"
                    className="form-control"
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <label htmlFor="">Cargo</label>
                  <input
                    type="text"
                    id="cargo"
                    name="cargo"
                    className="form-control"
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <label htmlFor="">Mensagem</label>
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <p className="subtext">
                  Ao preencher o formulário, aceito compartilhar minhas
                  informações com a IC Outsourcing e estou ciente da Política de
                  Privacidade.
                </p>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Cadastrar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
