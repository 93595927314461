import React from "react";

export const CallToAction = (props) => {
  return (
    <div id="call-to-action" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <a href="#contact" className="btn btn-custom btn-lg">
              Analisar minha demanda com o time comercial
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
