import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <div className="row text-center">
              <img src="img/about-1.png" alt="" />{" "}
              <img src="img/about-2.png" alt="" />{" "}
              <img src="img/about-3.png" alt="" />{" "}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text section-title">
              <h2>POR QUE CONTRATAR A IC OUTSOURCING?</h2>
              <h1>
                Diferenciais que você encontra na{" "}
                <span className="gradient-text">IC:</span>
              </h1>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
